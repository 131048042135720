// TODO: Add appropriate return type to generator so yield doesn't return any

import { select, call, put, takeEvery } from 'redux-saga/effects';
import get from 'lodash/get';

import { postFutureUsers } from 'common/core-future-accounts-api';
import { showSuccessToastNow, showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';
import I18n from 'common/i18n';
import { changeOrgUserActive, changeOrgUserRole, ACTIVATE, DEACTIVATE } from 'common/core/org_users';
import type { Role } from '@socrata/core-roles-api';

import * as actions from './actions';
import { translateTableAction } from './translate';
import * as usersTableActions from './OrganizationUsersTable/actions';
import * as addUsersActions from '../Users/AddUsersModal/actions';
import * as actionsBarActions from './ActionsBar/actions';
import * as addUsersModalActions from './AddUsersModal/actions';
import * as orgActions from 'organizationDashboard/actions/OrganizationActions';
import { fetchOrganizationUsersPage, fetchOrganizationUserPageWithCount } from 'organizationDashboard/util';
import { ORGANIZATION_USER_STATUS, PAGE_SIZE } from './OrganizationUsersTable/constants';
import { getCurrentSearchParams } from './OrganizationUsersTable/selectors';
import { getNumberOfUsers } from 'organizationDashboard/components/selectors';

import { AddUsersSuccessResponse } from 'organizationDashboard/components/Users/types';

export function* addUsers({ payload: { emails, roleId } }: ReturnType<typeof addUsersActions.addUsers>) {
  try {
    const response = (yield call(postFutureUsers, emails, roleId)) as AddUsersSuccessResponse;
    // successful response contains the added emails
    yield put(addUsersModalActions.addUsersSuccess(response));
    yield call(showSuccessToastNow, I18n.t('org_dashboard.users.add_user_modal.success'));
  } catch (error) {
    yield put(addUsersModalActions.addUsersFailed(error));
    yield call(showErrorToastNow, I18n.t('org_dashboard.users.add_user_modal.error'));
  }
}

export function* setUserRole({
  payload: { userId, roleId }
}: ReturnType<typeof usersTableActions.setUserRole>) {
  try {
    const response: { oldRole: Role; newRole: Role } = yield call(changeOrgUserRole, userId, roleId);
    yield put(usersTableActions.setUserRoleSuccess(userId, roleId, response));
    yield call(showSuccessToastNow, I18n.t('org_dashboard.users.change_user_role.success'));
  } catch (error) {
    yield call(showErrorToastNow, I18n.t('org_dashboard.users.change_user_role.failure'));
  }
}

export function* setUserStatusDeactivated(
  action: ReturnType<typeof usersTableActions.setUserStatusDeactivated>
) {
  const { user } = action.payload;

  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(changeOrgUserActive, user.uid, DEACTIVATE);

    yield put(usersTableActions.setUserStatusSuccess(user, ORGANIZATION_USER_STATUS.DEACTIVATED, response));
    yield put(orgActions.fetchOrg());
    yield call(showSuccessToastNow, translateTableAction('status.deactivate.success', { email: user.email }));
  } catch (error) {
    yield call(
      showErrorToastNow,
      translateTableAction('status.deactivate.failures.general', { email: user.email })
    );
  }
}

export function* setUserStatusActive(action: ReturnType<typeof usersTableActions.setUserStatusActive>) {
  const { user } = action.payload;

  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(changeOrgUserActive, user.uid, ACTIVATE);

    yield put(usersTableActions.setUserStatusSuccess(user, ORGANIZATION_USER_STATUS.ACTIVE, response));
    yield put(orgActions.fetchOrg());
    yield call(showSuccessToastNow, translateTableAction('status.activate.success', { email: user.email }));
  } catch (error) {
    yield call(showErrorToastNow, translateTableAction('status.activate.failures.general'));
  }
}

export function* fetchCurrentUsersTablePage() {
  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const searchParams = yield select(getCurrentSearchParams);
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const userCount = yield select(getNumberOfUsers);
    let nextPage;

    if (userCount < 0) {
      // if userCount is -1, we're doing our initial fetch
      // as part of this initial fetch, we also ask for the number of users
      // (this adds the `includeCount` parameter to the request)
      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
      nextPage = yield call(fetchOrganizationUserPageWithCount, searchParams);
      yield put(actions.setUserCount(get(nextPage, 'totalNumberOfUsers', -1)));
    } else {
      // otherwise the search params are chaning and we're doing a regular page fetch
      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
      nextPage = yield call(fetchOrganizationUsersPage, searchParams);
    }

    // if there aren't any results, we're either on an empty org or at the end of the list
    // so we do an early return with an empty page and no nextPage
    if (!nextPage.results) {
      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
      return yield put(usersTableActions.fetchCurrentPageSuccess([], false));
    }

    // when we make a request, we ask for 1 more result than we're actually displaying
    // if we get back more results that what we're displaying, we know that there's at
    // least one more page left
    const hasNextPage = nextPage.results.length > PAGE_SIZE;
    if (hasNextPage) {
      // since we asked for 1 extra result, we remove it here so it doesn't show up
      nextPage.results.pop();
    }

    yield put(usersTableActions.fetchCurrentPageSuccess(nextPage.results, hasNextPage));
  } catch (error) {
    yield put(usersTableActions.fetchCurrentPageFail(error));
  }
}

export function* searchParamsChange() {
  yield put(actions.resetSeek());
  yield call(fetchCurrentUsersTablePage);
}

export default [
  // load users for the first time
  // this is called by the table when it loads
  takeEvery(orgActions.INITIAL_LOAD, fetchCurrentUsersTablePage),

  takeEvery(addUsersModalActions.ADD_USERS, addUsers),
  takeEvery(addUsersModalActions.ADD_USERS_SUCCESS, fetchCurrentUsersTablePage),

  takeEvery(usersTableActions.ORG_USERS_FETCH_CURRENT_PAGE, fetchCurrentUsersTablePage),
  takeEvery(usersTableActions.ORG_USERS_GO_TO_NEXT_PAGE, fetchCurrentUsersTablePage),
  takeEvery(usersTableActions.ORG_USERS_GO_TO_PREVIOUS_PAGE, fetchCurrentUsersTablePage),
  takeEvery(usersTableActions.ORG_USERS_CHANGE_SORT_COLUMN, fetchCurrentUsersTablePage),
  takeEvery(actionsBarActions.ORG_USERS_APPLY_SEARCH, fetchCurrentUsersTablePage),
  takeEvery(actionsBarActions.ORG_USERS_SEARCH_PARAMS_CHANGE, searchParamsChange),
  takeEvery(usersTableActions.SET_USER_ROLE, setUserRole),
  takeEvery(usersTableActions.SET_USER_STATUS_ACTIVE, setUserStatusActive),
  takeEvery(usersTableActions.SET_USER_STATUS_DEACTIVATED, setUserStatusDeactivated)
];

import { call, put, select, takeEvery } from 'redux-saga/effects';
import get from 'lodash/get';

import * as actions from './actions';
import { fetchOrganizationUsersPage } from 'organizationDashboard/util';
import { getSiteAdminsSearchParams } from 'organizationDashboard/components/Sites/selectors';

import { User } from 'organizationDashboard/types';

export function* fetchAdmins() {
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const searchParameters = yield select(getSiteAdminsSearchParams);
  try {
    const response: { results?: User[] } = yield call(fetchOrganizationUsersPage, searchParameters);
    const results =
      Object.keys(get(response, 'results', {})).map((key) => {
        return response.results && response.results[key];
      }) || [];
    yield put(actions.fetchAdminsSuccessful(results));
  } catch (error) {
    yield put(actions.fetchAdminsFailed(error));
  }
}

export default [
  takeEvery(actions.FETCH_ADMIN_USERS, fetchAdmins),
  takeEvery(actions.SITE_ADMINS_CHANGE_SORT_COLUMN, fetchAdmins)
];
